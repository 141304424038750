<template>
<div>

  <v-snackbar v-model="snack" :timeout="3000" :color="snack_color">
    {{ snack_text }}
    <template v-slot:action="{ attrs }">
      <v-btn v-bind="attrs" text @click="snack = false">Close</v-btn>
    </template>
  </v-snackbar>

  <v-data-table
    :headers="headers"
    :items="filterd_data"
    :sort-by="sortBy"
    :sortDesc="sortTable"
    class="elevation-1"
    :loading="loading"
    :search="search"
  >
    <template v-slot:top>
      <v-toolbar flat v-if="showToolbar">
        <v-toolbar-title>{{title}}</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
          v-if="!isMobile || showDivider"
        ></v-divider>
        <slot name="top-right"></slot>
        <v-spacer v-if="!isMobile"></v-spacer>
        <v-row v-if="!isMobile">
          <v-col>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          </v-col>
        </v-row>

        <slot name="right-most"></slot>
      </v-toolbar>


      <!-- APPLIED FILTERS CHIPS BAR -->
      <v-divider v-if="applied_filters.length > 0"></v-divider>
      <v-row justify="space-around">
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <v-sheet flast class="py-1 px-2">
            <v-chip
              v-for="(filter,i) in applied_filters"
              :key="filter.col_name"
              close
              label
              color=""
              class="ml-1"
              @click:close="removeFilter(i,title)"
            >
            <b>{{filter.col_name | humanize}}</b> &nbsp;    
            [ {{ filter.rule_type }}: <small>{{filter.value}}</small> ]
            </v-chip>
          </v-sheet>
        </v-col>
      </v-row>
      <v-divider v-if="applied_filters.length > 0"></v-divider>
      <!-- #END APPLIED FILTERS CHIPS BAR -->

      <!-- Mobile Search Bar -->
      <v-divider v-if="isMobile"></v-divider>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        class="mx-5"
        v-if="isMobile"
      ></v-text-field>
      <v-divider v-if="isMobile" class="mt-3 mb-1"></v-divider>

      <slot name="top-bar-full-title"></slot>
    </template>

    <!-- Headers With Filters -->
    <template v-for="head in headers" v-slot:[`header.${head.value}`]="{ header }">
      <datatable-filter 
        :type="header.type"
        :table-name="title"
        :col-name="header.value"
        :fetch-url="header.fetch_url"
        v-if="head.filterable"
        @filter="handleFilterEvent(title)"
      >
        {{header.text}}
      </datatable-filter>
      <span v-else>{{head.text}}</span>
    </template>
    <!-- #END HEADERS With Filters -->

    <!-- SLOT TO EDIT COMMON FIELDS -->
    <template v-slot:item.Name="props">
      <v-edit-dialog
        :return-value.sync="props.item.Name"
        large
        persistent
        save-text="Update"
        @save="update(props.item)"
        @cancel="cancel"
      >
        <div>{{ props.item.Name }}</div>
        <template v-slot:input>
          <div class="mt-4 title">Update Name</div>
        </template>
        <template v-slot:input>
          <v-text-field
            v-model="props.item.Name"
            label="Edit Name"
            single-line
            autofocus
          ></v-text-field>
        </template>
      </v-edit-dialog>
    </template>


    <!-- SLOTS TO APPEND/SHOW BADGES -->
    <template v-slot:item.is_upcoming="{ item }">
      <v-chip label small :color="item.is_upcoming == 1 ? 'info' : 'secondary'" dark>
        <span v-if="item.is_upcoming == 1">Yes</span>
        <span v-else>No&nbsp;&nbsp;</span>
      </v-chip>
    </template>
    <template v-slot:item.status="{ item }">
      <v-chip v-if="item.status" class="status-badge" label small :color="getColor(item.status)" dark>
        {{ item.status }}
      </v-chip>
    </template>
    <!-- #END APPEND/SHOW BADGES SOT -->


    <!-- JOB TYPES ON POPOVER -->
    <template v-slot:item.job_types="{ item }">
      <v-menu open-on-hover bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <span class="hidden-text" v-bind="attrs" v-on="on">
            {{item.job_types}}
          </span>
        </template>

        <v-list v-if="item.job_types" color="primary" dark>
          <v-subheader>JOB TYPES</v-subheader>
          <v-divider></v-divider>
          <v-list-item
            v-for="(type, index) in item.job_types.split(',')"
            :key="index"
            dense
            @click=""
          >
            <v-list-item-title>{{ type }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <!-- #END JOB TYPES POPOVER -->

    <!-- SCHEDULE DATES ON POPOVER -->
    <template v-slot:item.schedule_dates="{ item }">
      <v-menu bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn outlined x-small color="primary" v-bind="attrs" v-on="on" :disabled="item.schedule_dates == null">
            Click to View
          </v-btn>
        </template>

        <v-list v-if="item.schedule_dates" color="primary" dark>
          <v-subheader>SCHEDULE DATES</v-subheader>
          <v-divider></v-divider>
          <v-list-item
            v-for="(date, index) in item.schedule_dates.split(',')"
            :key="index"
            dense
            @click=""
          >
            <v-list-item-title>{{ date }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <!-- #END SCHEDULE DATES POPOVER -->

    <!-- #is_completed slot -->
    <template v-slot:item.is_completed="{ item }">
      <v-icon v-if="item.is_completed == 1" color="success">mdi-check-circle</v-icon>
      <v-icon v-if="item.is_completed == 0 && item.schedule_dates != null" color="primary">mdi-clock-time-two</v-icon>
      <v-icon v-if="item.is_completed == 0 && item.schedule_dates == null" color="orange">mdi-progress-clock</v-icon>
    </template>
    <!-- #EDN is_completed slot  -->


    <!-- #logo slot -->
    <template v-slot:item.logo="{ item }">
      <a v-if="item.logo"  :href="getLogoUrl(item.logo)" target="_blank">
        <!-- <img :src="getLogoUrl(item.logo)" height="30" width="30" /> -->
        <v-img
          class="elevation-2"
          lazy-src="https://picsum.photos/id/11/10/6"
          max-height="60"
          max-width="60"
          :src="getLogoUrl(item.logo)"
        ></v-img>
      </a>
    </template>
    <!-- #END logo slot -->

    

    <template v-slot:item.custom_action="{ item }">
      <slot name="custom-column" :item="item"></slot>
    </template>
    <template v-slot:item.custom_action_1="{ item }">
      <slot name="custom-column-1" :item="item"></slot>
    </template>

    <template v-slot:item.modified_at="{ item }">
      {{getParsedDate(item.modified_at)}}
    </template>
    <template v-slot:item.modified="{ item }">
      {{getParsedDate(item.modified)}}
    </template>
    <template v-slot:[`item.Modified`]="{ item }">
      {{getParsedDate(item.Modified)}}
    </template>
    <template v-slot:item.created_at="{ item }">
      {{getParsedDate(item.created_at)}}
    </template>
    <template v-slot:item.updated_at="{ item }">
      {{getParsedDate(item.updated_at)}}
    </template>
    <template v-slot:item.created="{ item }">
      {{getParsedDate(item.created)}}
    </template>
    <template v-slot:[`item.Created`]="{ item }">
      {{getParsedDate(item.Created)}}
    </template>

    <!-- ACTIONS SLOT -->


    <template v-slot:item.actions="{ item }">

      <!-- Custom Action Slot, Passing Back row item -->
      <slot name="custom-action" :item="item"></slot>

      <!-- All Common Action Buttons -->
      <v-btn
        class="mr-2"
        @click="editItem(item)"
        v-if="showEdit && (!item['Deleted'])"
        color="primary"
        icon
        x-small
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        @click="restoreItem(item)"
        v-if="showDelete && (item['Deleted'] || item.deleted)"
        color="info"
        icon
        x-small
        :loading="is_deleteing == item.id || is_deleteing == item.ID"
        :disabled="is_deleteing == item.id || is_deleteing == item.ID"
        class="mr-2"
      >
        <v-icon>mdi-backup-restore</v-icon>
      </v-btn>
      <v-btn
        v-else-if="showDelete"
        @click="openDialog(item)"
        color="red"
        icon
        small
        :loading="is_deleteing == item.id || is_deleteing == item.ID"
        :disabled="is_deleteing == item.id || is_deleteing == item.ID"
        class="mr-2"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>


      <v-btn
        v-if="showView"
        @click="viewItem(item)"
        color="primary"
        icon
        x-small
        class="mr-2"
      >
        <v-icon>mdi-eye-circle</v-icon>
      </v-btn>
      <v-btn
        v-if="showViewDoc"
        @click="viewItem(item)"
        color="info darken-1"
        icon
        x-small
      >
        <v-icon>mdi-text-box-search</v-icon>
      </v-btn>


      <v-btn
        v-if="showReRequest && item.status_id == 3"
        @click="reRequestItem(item)"
        color="info darken-1"
        icon
        x-small
      >
        <v-icon>mdi-repeat</v-icon>
      </v-btn>


    </template>
    <template v-slot:no-data>
      <!-- Default Message -->
    </template>
  </v-data-table>

  <!-- DELETE CONIRMATION POPUP -->
  <v-dialog v-model="dialog" persistent max-width="380">
    <v-card>
      <v-card-title class="subtitle">Are you sure, you want to delete it?</v-card-title>
      <v-card-text><center><small>It may impact the other data where it's being used.</small></center></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary darken-1" text @click="dialog = false">No</v-btn>
        <v-btn color="red darken-1" text @click="deleteItem">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</div>
</template>

<script>
export default {
  name : 'GeneralDataTable',
  props: {
    title : {
      type   : String,
      default : ''
    },
    headers : {
      type    : Array,
      default : () => { return [] }
    },
    items : {
      type    : [Array,Object], 
      default : () => {}
    },
    loading : {
      type    : Boolean,
      default : false 
    },
    showEdit : {
      type    : Boolean,
      default : false
    },
    showDelete : {
      type    : Boolean,
      default : false
    },
    showView : {
      type    : Boolean,
      default : false
    },
    showReRequest : {
      type    : Boolean,
      default : false
    },
    showViewDoc : {
      type    : Boolean,
      default : false
    },
    showDivider : {
      type    : Boolean,
      default : false
    },
    showToolbar : {
      type    : Boolean,
      default : true
    },
    deleteUrl : {
      type     : String,
      default  : null
    },
    updateUrl : {
      type    : String,
      default : null
    },
    customSlots : {
      type    : Array,
      default : () => []
    },
    sortTable : {
      type    : Boolean,
      default : true
    }
  },
  data: () => ({
    is_deleteing    : false,
    dialog          : false,
    snack           : false,
    snack_color     : '',
    snack_text      : '',
    activeItem      : {},
    search          : null, 
    applied_filters : [],
    filterd_data    : []
  }),
  computed:{
    sortBy(){

      if(this.sortTable == false) { return '' }

      if (Object.values(this.items).length > 0 && Object.values(this.items)[0].hasOwnProperty('ID')) {
        return 'ID'
      }
      else{
        return 'id'
      }
    }
  },
  watch:{
    loading(val){
      if (val == false){
        this.handleFilterEvent(this.title)
      }
    }
  },
  mounted(){
    this.filterd_data = this.items
    this.getFilters(this.title)

    this.$nextTick(()=>{
      this.handleFilterEvent(this.title)
    })
  },
  methods: {
    /**
    * @Description
    * 
    * 1. This method is used to get the Status Color for badge background
    * 2. It receives the status as a string and returns the appropriate color name
    * 
    * @param status { String }
    * @return String 
    **/
    getColor (status) {
      switch(status){
        case 'Under Review':
        case 'InProcess':
        case 'New':
          return 'orange'
        case 'Received':
        case 'Sent':
          return 'deep-purple'
        case 'Completed':
        case 'Resolved':
        case 'Approved':
          return 'green'
        case 'Declined':
          return 'red'
        case 'Scheduled':
          return 'primary'
      }
    },
    editItem (item){
      this.$emit('edit',item)
    },
    viewItem (item){
      this.$emit('view',item)
    },
    reRequestItem (item){
      this.$emit('rerequest',item)
    },
    openDialog(item){
      this.activeItem = item
      this.dialog     = true
    },
    /**
    * @Description
    * 
    * 1. This method is used to delete the entery from backend
    * 2. Since this is a shared common component, so this method is acting also dynamically for all endpoints
    * 3. If first close the confiration dialog, the Yes/No dialog  which was opend for the the confirmation
    * 4. Then it dispatches the HTTP Delete Request to backend
    * 5. If successfully deleted, then it show the success message and emits 'reload' to parent component
    * 6. The emit to parent is because, parent table have to reload the data after deleting a recordin
    * 
    * @params none
    * @return void
    **/
    deleteItem(){
      this.dialog       = false
      let item          = this.activeItem
      this.is_deleteing = item.id || item.ID
      
      this.$store.dispatch('HTTP_DELETE_REQUEST',`${this.deleteUrl}/${item.id || item.ID}`).then(response=>{
        if(response.message && response.message == 'success'){
          this.snack       = true
          this.snack_color  = 'success'
          this.snack_text   = `Item deleted successfully.`
          this.$emit('reload',true)
        }
      })
      .catch(error=>{
        console.log(error)
      })
      .finally(f=>{
        this.is_deleteing = false
      })
    },
    /**
    * @Description
    * 
    * 1. This method is used to restire the deleted item
    * 2. Since it's a shared component, then this method is also acting as dynamic method
    * 3. It gets the delete url from props and send request on that url with selected item row ID
    * 4. If successfully restored, shows the success message and emits 'reload'
    * 5. The reload emit is because parent table have to reload the data
    * 
    * @param item { Object }
    * @return void
    **/
    restoreItem(item){
      this.is_deleteing = item.id || item.ID
      this.$store.dispatch('HTTP_PUT_REQUEST',{url: `${this.deleteUrl}/${item.id || item.ID}/restore`, payload: {}}).then(response=>{
        if(response.message && response.message == 'success'){
          this.snack       = true
          this.snack_color  = 'success'
          this.snack_text   = `Item restored successfully.`
          this.$emit('reload',true)
        }
      })
      .catch(error=>{
        console.log(error)
      })
      .finally(f=>{
        this.is_deleteing = false
      })
    },
    /**
    * @Description
    * 
    * 1. This method is used to update the common endpoints, normally this method is being used for the  dictionaries
    * 2. It gets the update url in props to act as a dynamic method , sets the selected item id
    * 3. Then it dispatches the HTTP PUT request on backend
    * 4. If successfully restored, shows the success message and emits 'reload'
    * 5. The reload emit is because parent table have to reload the data
    * 
    * @param data { Object }
    * @return void
    **/
    update(data){
      let dataToPost = {
        url : `${this.updateUrl}/${data.id || data.ID}`,
        payload : {
          id   : data.id,
          Name : data['Name']
        }
      }
      this.$store.dispatch('HTTP_PUT_REQUEST',dataToPost).then(res=>{
        if (res.message && res.message == 'success') {
          this.snack      = true
          this.snack_color = 'success'
          this.snack_text  = 'Item updated successfully.'
          this.$emit('reload', true)
        }else{
          this.snack      = true
          this.snack_color = 'error'
          this.snack_text  = 'Something went wrong while saving data.'
        }
      })
      .catch(error=>{
        console.log(error)
        this.snack      = true
        this.snack_color = 'error'
        this.snack_text  = 'Something went wrong while saving data.'
      })
    },
    /**
    * @Description
    * 
    * 1. This method is used to close the edit dialog on name slot
    *
    * @params none
    * @return void
    **/
    cancel(){
      this.snack       = true
      this.snack_color = 'error'
      this.snack_text  = 'Item Update Canceled'
    },
    getLogoUrl(logoUrl){
      return process.env.VUE_APP_API_BASE_URL + logoUrl
    }
  }
};
</script>

<style scopped>
.hidden-text{
  display       : inline-block;
  width         : 180px;
  white-space   : nowrap;
  overflow      : hidden !important;
  text-overflow : ellipsis;
  cursor        : pointer;
}
.status-badge{
  width      : 85%;
  text-align : center;
}
@media only screen and (max-width: 600px) {
  .status-badge{
    width: 100%;
  }
}
</style>