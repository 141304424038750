<template>
  <v-app>
    <v-card class="rounded-0 elevation-0">
      <v-toolbar
        :dark="!isDark"
        tabs
        flat
        :color="getMenuColor"
        class="elevation-3 pr-2"
      >
        <v-img
          src="./assets/scaffolding-logo.webp"
          max-height="40"
          max-width="40"
          class="mr-2 ml-2"
        ></v-img>

        <v-toolbar-title v-if="!isMobile">SILVERCUPSCAFFOLDING | CRM <small>(ADMIN PORTAL)</small></v-toolbar-title>
        <v-toolbar-title v-if="isMobile">SILVERCUP | CRM</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-divider
          inset
          vertical
        ></v-divider>

        <v-tooltip left color="info" v-if="!isDark">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="white"
              v-bind="attrs"
              v-on="on"
              to="/crm-issues"
            >
              <v-icon>mdi-bug</v-icon>
            </v-btn>
          </template>
          <span>CRM Issues</span>
        </v-tooltip>
        <v-divider
          inset
          vertical
        ></v-divider>

        <v-tooltip left color="info" v-if="!isDark">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="white"
              v-bind="attrs"
              v-on="on"
              @click="toggleTheme"
            >
              <v-icon>mdi-brightness-4</v-icon>
            </v-btn>
          </template>
          <span>Dark Mode</span>
        </v-tooltip>
        <v-tooltip left color="info" v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="white"
              v-bind="attrs"
              v-on="on"
              @click="toggleTheme"
            >
              <v-icon>mdi-brightness-6</v-icon>
            </v-btn>
          </template>
          <span>Light Mode</span>
        </v-tooltip>
        <v-divider
          inset
          vertical
        ></v-divider>
        <v-tooltip left color="info">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="white"
              v-bind="attrs"
              v-on="on"
              @click="$router.push('/profile')"
            >
              <v-icon>mdi-account-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>Profile</span>
        </v-tooltip>
        <v-divider
          inset
          vertical
        ></v-divider>
        <v-tooltip left color="info">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="white"
              v-bind="attrs"
              v-on="on"
              @click="logout"
            >
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </template>
          <span>Logout</span>
        </v-tooltip>

        <template v-slot:extension>
          <v-tabs
            v-model="tabs"
            align-with-title
            next-icon="mdi-arrow-right-bold-box-outline"
            prev-icon="mdi-arrow-left-bold-box-outline"
            show-arrows
          >
            <v-tab to="/">
              Home
            </v-tab>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-tab v-bind="attrs" v-on="on">
                  Jobs
                </v-tab>
              </template>
              <v-list>
                <v-list-item to="/jobs">
                  <v-list-item-title>Jobs Listing</v-list-item-title>
                </v-list-item>
                <v-list-item to="/job-schedule">
                  <v-list-item-title>Job Schedules</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item to="/temp">
                  <v-list-item-title>
                    Job Schedules
                     <v-chip color="primary" x-small>New</v-chip>
                  </v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item to="/estimation">
                  <v-list-item-title>
                    Estimations
                  </v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item to="/incidents">
                  <v-list-item-title>
                    Incidents
                    <v-chip color="deep-purple white--text" x-small>New</v-chip>
                  </v-list-item-title>
                </v-list-item>
                
              </v-list>
            </v-menu>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-tab v-bind="attrs" v-on="on">
                  Docs & Pics
                </v-tab>
              </template>
              <v-list>
                <v-list-item to="/documents">
                  <v-list-item-title>Documents</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item to="/all-job-pictures">
                  <v-list-item-title>All Jobs Pictures</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-tab v-bind="attrs" v-on="on">
                  Orders & Inventory
                </v-tab>
              </template>
              <v-list>
                <v-list-item to="/orders">
                  <v-list-item-title>Orders Listing</v-list-item-title>
                </v-list-item>
                <v-list-item to="/order-submission">
                  <v-list-item-title>Order Submission</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item to="/materials">
                  <v-list-item-title>Materials</v-list-item-title>
                </v-list-item>
                <v-list-item to="/inventory-submission">
                  <v-list-item-title>Inventory Submission</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-tab v-bind="attrs" v-on="on">
                  Companies
                </v-tab>
              </template>
              <v-list>
                <v-list-item to="/companies">
                  <v-list-item-title>Companies List</v-list-item-title>
                </v-list-item>
                <v-list-item to="/vendors">
                  <v-list-item-title>Vendors</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-tab to="/contacts">
              Contacts
            </v-tab>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-tab v-bind="attrs" v-on="on">
                  Reports
                </v-tab>
              </template>
              <v-list>
                <v-list-item to="/custom-reports">
                  <v-list-item-title>Custom Reports</v-list-item-title>
                </v-list-item>
                <v-list-item to="/order-report">
                  <v-list-item-title>Order Report</v-list-item-title>
                </v-list-item>
                <v-list-item to="/upcoming-jobs">
                  <v-list-item-title>Upcoming Jobs</v-list-item-title>
                </v-list-item>
                <v-list-item to="/used-materials">
                  <v-list-item-title>Used Materials</v-list-item-title>
                </v-list-item>
                <v-list-item to="/insurance-policies">
                  <v-list-item-title>Insruance Policies</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-tab to="/reminders">
              Reminders
            </v-tab>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-tab v-bind="attrs" v-on="on">
                  Users
                </v-tab>
              </template>
              <v-list>
                <v-list-item to="/users">
                  <v-list-item-title>All Users</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item to="/role-permissions">
                  <v-list-item-title>Role's Permissions <v-chip color="primary" x-small>New</v-chip></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-tab to="/chat">
              Chat
            </v-tab>
            <v-tab to="/dictionaries">
              Dictionaries
            </v-tab>
            <v-tab to="/trucks">
              Trucks
            </v-tab>
            <v-tabs-slider color="pink"></v-tabs-slider>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-card-text>
        <v-main class="lighten-2 pa-0">
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <router-view :key="$route.fullPath"></router-view>
              </v-col>
            </v-row>
          </v-container>
        </v-main>
      </v-card-text>

    </v-card>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    tabs   : null,
  }),
  computed:{
    isDark(){
      return this.$store.state.DARK_MODE
    },
    getMenuColor(){
      return  this.isDark ? '' : 'indigo'
    }
  },
  created(){
    this.$vuetify.theme.dark = this.isDark

    // window.onbeforeunload = () => (this.$store.state.ANY_FORM_STATE_CHANGED == true  ? true : null);
  },
  watch:{
    '$store.state.DARK_MODE' : function(val){
      this.$vuetify.theme.dark = val
    }
  },
  methods:{
    toggleTheme(){
      let mode = this.$store.state.DARK_MODE
      this.$vuetify.theme.dark = !mode
      this.$store.commit('setThemeState', !mode)
    }
  }
};
</script>

<style type="text/css">
  /* width */
  ::-webkit-scrollbar {
    width: 6px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #5C6BC0;
    border-radius: 5px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #3949AB; 
  }
</style>